module.exports = initWidget

function initWidget() {
  return { offerCarousel: ['sectionAdmin', init] }
}

function init(serviceLocator, done) {
  const widget = {
    name: 'Offer Carousel',
    description: 'Displays a carousel',
    model: require('./models/model'),
    editView: require('./views/form'),
    itemView: require('./views/item')
  }
  ;['section'].forEach(factory =>
    serviceLocator.widgetFactories
      .get(factory)
      .register('offerCarousel', widget)
  )
  done()
}
